import springApi from '../../services/springApi'

export const getResellCampaigns = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/resell_campaigns', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeResellCampaign = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/resell_campaigns', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateResellCampaign = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/resell_campaigns/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteResellCampaign = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/resell_campaigns/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)