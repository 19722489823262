import springCrmApi from "../../services/springCrmApi"

const baseUrl = '/public/resell_campaigns/customer_data'

export const eligibilityByDocument = data => (
	new Promise((resolve, reject) => {
		springCrmApi()
			.post(`${baseUrl}/eligibility_by_document`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCustomerData = data => (
	new Promise((resolve, reject) => {
		springCrmApi()
			.post(`${baseUrl}/update_customer_data`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const sendRewardSelected = data => (
	new Promise((resolve, reject) => {
		springCrmApi()
			.post(`${baseUrl}/send_reward_selected`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const registerLinkClick = data => (
	new Promise((resolve, reject) => {
		springCrmApi()
			.post(`${baseUrl}/register_link_click`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)