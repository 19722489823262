import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ResellCampaignForm from './ResellCampaignForm'

import { handleError } from '../../../helpers'
import { storeResellCampaign } from '../services'

export default function NewResellCampaignModal({ visible, onClose, reload }) {
	const { register, formState: { errors }, handleSubmit } = useForm({})

	const onSubmit = values => {
		storeResellCampaign(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Crear Campaña</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ResellCampaignForm register={register} errors={errors} />
					<Button color="primary" type="submit">Crear Campaña</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}