import React, { useEffect } from 'react'
import { registerLinkClick } from '../CustomerData/services'
import { Spinner } from 'react-bootstrap'

export default function PromoShortLink() {

	useEffect(() => {
		registerLinkClick({ url: window.location.href })
			.then(() => {
				window.open('https://www.colchonesspring.com.co/colchon-mega-doble/p?utm_source=SMS&utm_medium=SMS&utm_resell_campaign=ecommerce_spring&utm_term=blackdays_May16_mega', '_self')
			})
			.catch(error => console.error(error))
	}, [])

	return (
		<React.Fragment>
			<Spinner animation='border' />
		</React.Fragment>
	)
}