import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import { storeCallLog } from '../services'
import { handleError } from '../../../helpers'

export default function NewCallLogModal({ visible, onClose, customer, invoiceId, reload }) {
	const { document } = useSelector(state => state.auth.user)
	const { register, formState: { errors }, handleSubmit } = useForm({
		defaultValues: {
			client_doc: customer.document
		}
	})

	const onSubmit = values => {
		storeCallLog({ 
			agent_doc: document, 
			invoice_id: invoiceId, 
			...values
		})
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Registrar Llamada</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label>Cédula de Contacto</Form.Label>
						<Form.Control {...register('client_doc')} disabled />
					</Form.Group>
					<Form.Group>
						<Form.Label>Celular de Contacto</Form.Label>
						<Form.Control value={`${customer.phone ? customer.phone : ''} - ${customer.mobile ? customer.mobile : ''}`} disabled />
					</Form.Group>
					<Form.Group>
						<Form.Label>Resultado <span className='text-danger'>*</span></Form.Label>
						<Form.Control
							as="select"
							{...register('outcome', { required:true })}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option>No contesto</option>
							<option>Prefiere no recibir más llamadas</option>
							<option>Interesado en comprar</option>
							<option>Pendiente de Seguimiento</option>
						</Form.Control>
						{ errors.outcome && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Comentarios <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							{...register('comment', { required: true })}
							placeholder='Escriba aquí'
							as="textarea"
							style={{ height: '90px' }}
						/>
						{ errors.comment && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Button type="submit" color='primary'>Registrar Llamada</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}