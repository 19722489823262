import React from 'react'
import { Badge } from 'reactstrap'
import { Table } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'

export default function SaleLogsTable({ saleLogs, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Fecha Registro',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MM-YYYY hh:mm A')
		},
		{
			title: 'Factura',
			dataIndex: 'invoice_ref',
			render: t => t.toUpperCase()
		},
		{
			title: 'Cédula Cliente',
			dataIndex: 'client_doc',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Detalle del Estado',
			dataIndex: 'status',
			render: t => t.hint
		}
	]

	const expandedRowRender = record => (
		<React.Fragment>
			<p className='mb-0'><strong>Comentarios del asesor: </strong>{record.comments}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={saleLogs}
				columns={columns}
				rowKey={r => r.id}
				loading={!saleLogs}
				pagination={false}
				size="small"
				expandable={{ expandedRowRender }}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}