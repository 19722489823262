import React from 'react'
import { Form } from 'react-bootstrap'

export default function ResellCampaignForm({ register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('description', { required: true })}
					placeholder="Escriba aquí..."
					as="textarea"
					style={{ height: '100px' }}
				/>
				{ errors.description && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Incentivos <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('incentives', { required: true })}
					placeholder="Escriba aquí..."
					as="textarea"
					style={{ height: '100px' }}
				/>
				{ errors.incentives && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Estado <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('is_active', { required: true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value={1}>Activa</option>
					<option value={0}>Inactiva</option>
				</Form.Control>
				{ errors.is_active && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}