import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ResellCampaignForm from './ResellCampaignForm'

import { handleError } from '../../../helpers'
import { updateResellCampaign } from '../services'

export default function EditResellCampaignModal({ visible, onClose, resell_campaign, reload }) {
	const { register, formState: { errors }, handleSubmit } = useForm({
		defaultValues: { ...resell_campaign }
	})

	const onSubmit = values => {
		updateResellCampaign(resell_campaign.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Campaña</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ResellCampaignForm register={register} errors={errors} />
					<Button color="primary" type="submit">Actualizar Campaña</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}