import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ResellCampaignsList from './ResellCampaignsList'

export default function ResellCampaigns() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/resell_campaigns" component={ResellCampaignsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}