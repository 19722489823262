import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Form } from 'react-bootstrap'

import ResellCampaignsTable from './partials/ResellCampaignsTable'
import NewResellCampaignModal from './partials/NewResellCampaignModal'
import ListTopBar from '../../components/ListTopBar'

import { getResellCampaigns } from './services'
import { handleError, parsePagination } from '../../helpers'
import { message } from 'antd'

export default function ResellCampaignsList() {
	const user = useSelector(state => state.auth.user)
	const [resell_campaigns, setResellCampaigns] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)
	const [onlyActive, setOnlyActive] = useState(true)

	useEffect(() => {
		!resell_campaigns && getResellCampaigns({
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': onlyActive ? 1 : null,
			...pagination,
		})
			.then(res => {
				setResellCampaigns(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ resell_campaigns, filterType, filterValue, pagination, onlyActive ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Campañas Recompra Fiel Momentos`}
				subtitle="Momentos Spring"
				reload={() => setResellCampaigns(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				ctaText="Crear Campaña"
				handleCtaClick={() => user.role === 'admin' ? setShowNewModal(true) : message.warning('Funcionalidad solo para administradores')}
			/>
			<Card>
				<CardBody>
					<Form.Check
						label="Mostrar solo campañas activas" 
						checked={onlyActive}
						onChange={() => {
							setOnlyActive(!onlyActive)
							setResellCampaigns(null)
						}}
						className='block mb-2'
					/>
					<ResellCampaignsTable
						resell_campaigns={resell_campaigns}
						reload={() => setResellCampaigns(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewResellCampaignModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setResellCampaigns(null)}
				/>
			)}
		</React.Fragment>
	)
}