import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import ResellCampaigns from "./containers/ResellCampaigns"
import Customers from "./containers/Customers"
import Invoices from "./containers/Invoices"
import SaleLogs from "./containers/SaleLogs"
import Segments from "./containers/Segments"
import CustomerData from "./containers/CustomerData"
import PromoShortLink from "./containers/ResellCampaigns/PromoShortLink"
import Reports from "./containers/Reports"
import CallLogs from "./containers/CallLogs"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/invoices" />

				<Route path="/promo" component={PromoShortLink} />

				<Route path="/actualizar_datos" component={CustomerData} />
				<Route path="/completar_mis_datos" component={CustomerData} />
				<Route path="/completar_datos_asesor" component={CustomerData} />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/customers" component={Customers} />
					<Route path="/invoices" component={Invoices} />
					<Route path="/resell_campaigns" component={ResellCampaigns} />
					<Route path="/sale_logs" component={SaleLogs} />
					<Route path="/call_logs" component={CallLogs} />
					<Route path="/segments" component={Segments} />
					<Route path="/reports" component={Reports} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}